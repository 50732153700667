import { Tooltip, TooltipProps, tooltipClasses } from "@mui/material";
import { Icon, LegacyStack, Select, SelectOption, Text } from "@shopify/polaris";
import { InfoMinor } from "@shopify/polaris-icons";
import React, { SelectHTMLAttributes } from "react";
import styled from "styled-components";

import { ISelectOption } from "../useTypedForm";

interface DropdownElementProps extends Omit<SelectHTMLAttributes<HTMLSelectElement>, "onChange" | "onBlur"> {
  value: string;
  label: string;
  options: ISelectOption<string>[];
  onChange(newValue: any): void;
  onBlur(): void;
  usePolaris?: boolean;
  toolTip?: NonNullable<React.ReactNode> | undefined;
}

export function DropdownElement({
  className,
  options,
  value,
  usePolaris,
  label,
  onChange,
  onBlur,
  toolTip,
  ...props
}: DropdownElementProps): JSX.Element {
  if (usePolaris) {
    const finalOptions: SelectOption[] = options.map(({ value: optionValue, label, disabled }) => ({
      value: optionValue,
      label: label || value,
      disabled: disabled || false,
    }));
    if (toolTip) {
      return (
        <React.Fragment>
          <LegacyStack spacing="tight">
            <Text as="span" variant="bodyMd">
              {label}
            </Text>
            <StyledToolTip className={className} title={toolTip} placement="right">
              <span style={{ cursor: "pointer" }}>
                <Icon source={InfoMinor} color="subdued" />
              </span>
            </StyledToolTip>
          </LegacyStack>
          <Select
            label=""
            options={finalOptions}
            value={value}
            onChange={selected => onChange(selected)}
            onBlur={onBlur}
          />
        </React.Fragment>
      );
    }
    return (
      <div className={className}>
        <Select
          label={label || ""}
          options={finalOptions}
          value={value}
          onChange={selected => onChange(selected)}
          onBlur={onBlur}
        />
      </div>
    );
  }

  return (
    <div className={className}>
      <select {...props} value={value} onChange={e => onChange(e.target.value)} onBlur={onBlur}>
        {options.map(o => (
          <option key={o.label || o.value} value={o.value}>
            {o.label || o.value}
          </option>
        ))}
      </select>
    </div>
  );
}

const StyledToolTip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  "&": {
    inset: "-90px auto auto 0px !important",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    marginLeft: "0px !important",
    color: "#202223",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "20px",
    borderRadius: "4px",
    backgroundColor: "#fff",
    boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.10), 0px 0px 2px 0px rgba(0, 0, 0, 0.20)",
    padding: "9px !important",
    width: "190px",
  },
}));
